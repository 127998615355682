import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer, CustomNavLink,
} from "./styles";
import {EnvironmentOutlined, EnvironmentTwoTone, FacebookFilled, MailTwoTone, PhoneTwoTone} from "@ant-design/icons";

// interface SocialLinkProps {
//   href: string;
//   src: string;
// }

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  const FacebookLinkAnt = ({ href }: any) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
        >
          <FacebookFilled style={{ fontSize: '45px', color: '#08c' }} />
        </a>
    );
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
    // setVisibility(false);
  };

  return (
    <>
      <FooterSection id="contact">
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Questions about the restaurant? Have a large order?`)}
                <a href="tel:+12088531594"><Chat><PhoneTwoTone /> Call us</Chat></a>
              </Para>
              {/*<br />*/}
              {/*<Para>*/}
              {/*  {t(`For questions or issues with the website`)}*/}
              {/*  <a href="mailto:unclegiuseppes@be3.dev">*/}
              {/*    <Chat><MailTwoTone /> {t(`Email Us`)}</Chat>*/}
              {/*  </a>*/}
              {/*</Para>*/}
            </Col>
            <Col lg={10} md={10} sm={12} xs={12} id="location">
              <Empty />
              <a href="https://www.google.com/maps?q=Uncle+Giuseppes,+North+Glenwood+Street,+Garden+City,+ID+83714">
              <Chat><EnvironmentTwoTone />&nbsp;{t("Come See Us")}</Chat>
              <Para>6826 N Glenwood St</Para>
              <Para>Garden City</Para>
              <Para>ID 83714</Para>
              </a>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <CustomNavLink onClick={() => scrollTo("top")}>
              <LogoContainer>
                <SvgIcon
                  src="ug_logo.png"
                  aria-label="homepage"
                  width="354px"
                  height="198px"
                />
              </LogoContainer>
            </CustomNavLink>
            <FooterContainer>
              <FacebookLinkAnt style={{ fontSize: '40px' }}
                href="https://www.facebook.com/profile.php?id=100040696076014"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
